import React, { useContext } from 'react';
import { Grid } from '@mui/material';
import Editor from '@components/Editor';
import ContainerComponent from '@components/ContainerComponent';
import { EventNameAndThemeContext, SloganContext } from '@/store/context';
import { SloganActionTypes } from '@/store/types';
import { ComponentViewProps } from '@/pages/admin/views/interfaces';

export default function Slogan({ defaultTheme }: ComponentViewProps) {
  const { eventNameAndThemeEditor } = useContext(EventNameAndThemeContext);
  const { sloganEditor, sloganDispatch } = useContext(SloganContext);
  return (
    <Grid container>
      <Grid item xs={6}>
        <Editor
          area-label="Slogan Editor"
          label="Slogan"
          value={sloganEditor.slogan}
          setValue={(val: string) =>
            sloganDispatch({ type: SloganActionTypes.setSlogan, payload: val })
          }
        />
      </Grid>
      <Grid item xs={6}>
        <ContainerComponent
          area-label="Slogan View"
          background={eventNameAndThemeEditor?.background?.name}
          id="second"
          image={eventNameAndThemeEditor?.background?.secondPage || defaultTheme?.secondPage}
          footer={{ data: sloganEditor?.slogan, xs: 1 }}
        />
      </Grid>
    </Grid>
  );
}
