import React, { SetStateAction, useContext, useEffect } from 'react';
import { FormControl, Grid, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { ConfigurationProps } from '@adminComponents/Sidebar/views/Configuration/index';
import { fetchAllTagungsAppConfigs } from '@/api/admin/adminApi';
import { SupportedThemes, themesMap } from '@/utils/themes';
import {
  AgendaActionTypes,
  AppStateType,
  EventNameAndAgendaActionTypes,
  ImprintActionTypes,
  IntroActionTypes,
  LocationMetaDataActionTypes,
  SitemapItemsActionTypes,
  SloganActionTypes,
} from '@/store/types';
import { callToastNotification, toastIds } from '@/utils/toastify';
import {
  AgendaContext,
  ImprintContext,
  IntroContext,
  LocationMetaDataContext,
  SloganContext,
} from '@/store/context';
import { eventNameAndThemeState } from '@/store/context/EventNameAndThemeContext';
import { SitemapItemsContext } from '@/store/context/SitemapItemsContext';

export default function Configuration({
  editor,
  dispatch,
  allEvents,
  setAllEvents,
}: ConfigurationProps) {
  const { introDispatch } = useContext(IntroContext);
  const { sloganDispatch } = useContext(SloganContext);
  const { locationMetaDataDispatch } = useContext(LocationMetaDataContext);
  const { agendaDispatch } = useContext(AgendaContext);
  const { imprintDispatch } = useContext(ImprintContext);
  const { sitemapItemsDispatch } = useContext(SitemapItemsContext);
  const getEventsList = async (setAllEvents: React.Dispatch<SetStateAction<AppStateType[]>>) => {
    try {
      const eventsList = await fetchAllTagungsAppConfigs();
      setAllEvents(eventsList);
    } catch (error) {
      if (error instanceof Error) {
        callToastNotification(toastIds.error, error.message);
        return;
      }
      callToastNotification(toastIds.error, 'An error occurred.');
    }
  };
  useEffect(() => {
    getEventsList(setAllEvents);
  }, []);
  const selectEventHandler = (evt: SelectChangeEvent) => {
    const {
      target: { value },
    } = evt;
    const chosenEvent = allEvents?.find((tagungsEvent) => tagungsEvent.eventName === value);
    if (chosenEvent) {
      dispatch({
        payload: chosenEvent.eventName,
        type: EventNameAndAgendaActionTypes.setEventName,
      });
      dispatch({
        payload: chosenEvent.background?.name,
        type: EventNameAndAgendaActionTypes.setBackground,
      });
      introDispatch({
        payload: chosenEvent.intro,
        type: IntroActionTypes.setIntro,
      });
      sloganDispatch({
        payload: chosenEvent.slogan,
        type: SloganActionTypes.setSlogan,
      });
      locationMetaDataDispatch({
        payload: chosenEvent.locationMetaData,
        type: LocationMetaDataActionTypes.setLocationDetails,
      });
      agendaDispatch({
        payload: chosenEvent.agenda,
        type: AgendaActionTypes.setAgenda,
      });
      imprintDispatch({
        payload: chosenEvent.imprint,
        type: ImprintActionTypes.setImprint,
      });
      sitemapItemsDispatch({
        payload: chosenEvent.sitemapItems,
        type: SitemapItemsActionTypes.setSitemapItemsDragAndDrop,
      });
    }
    if (!chosenEvent) {
      callToastNotification(toastIds.setSelectedEventFailure);
    }
  };
  return (
    <Grid
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <FormControl>
        <Select
          aria-label="Choose an event"
          labelId="select"
          value={editor.eventName}
          onChange={(evt) => selectEventHandler(evt)}
        >
          {allEvents?.map((event) => (
            <MenuItem key={event.eventName} value={event.eventName}>
              {event.eventName}
            </MenuItem>
          ))}
          <MenuItem value={eventNameAndThemeState.eventName}>
            <span>Ein neues Event anlegen.</span>
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl style={{ marginTop: '1rem' }}>
        <Select
          aria-label="Choose a background"
          labelId="select"
          value={editor.background?.name}
          onChange={(event: SelectChangeEvent) => {
            dispatch({
              payload: event.target.value,
              type: EventNameAndAgendaActionTypes.setBackground,
            });
          }}
        >
          {Array.from(themesMap.keys()).map((background: SupportedThemes) => (
            <MenuItem key={background} value={background}>
              {background}
            </MenuItem>
          ))}
          <MenuItem value={eventNameAndThemeState.background.name}>
            <span>Ein neues background wälen.</span>
          </MenuItem>
        </Select>
      </FormControl>
      <Grid item xs={12}>
        <TextField
          aria-label="Event Name"
          style={{ marginTop: '1rem' }}
          variant="standard"
          value={editor.eventName}
          placeholder="Event Name"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            dispatch({
              type: EventNameAndAgendaActionTypes.setEventName,
              payload: event.currentTarget.value,
            });
          }}
        />
      </Grid>
    </Grid>
  );
}
