import React from 'react';
import { Typography } from '@mui/material';
import { StyledContainer } from '@components/Contact/styles';
import { createMarkup } from '@/utils/createMarkup';

interface ContactProps {
  value: string;
  background: string;
}

export default function Contact({ value, background }: ContactProps) {
  return (
    <StyledContainer>
      <Typography
        variant={background === 'office' ? 'imprintOfficeParagraph' : 'imprintParagraph'}
        dangerouslySetInnerHTML={createMarkup(value)}
      />
    </StyledContainer>
  );
}
