import React, { ReactNode } from 'react';
import { providersMap, SupportedProviders } from '@/utils/providersMap';
import { ContextProvidersProps } from '@/types';

export const WithContextProviders: React.FC<ContextProvidersProps> = function ({ children }) {
  const composedProviders = Object.values(SupportedProviders).reduce(
    (acc: ReactNode, provider: SupportedProviders) => {
      const ProviderComponent = providersMap.get(provider)?.Provider;
      if (!ProviderComponent) return null;
      return <ProviderComponent>{acc}</ProviderComponent>;
    },
    children
  );
  return <div>{composedProviders}</div>;
};

export default WithContextProviders;
