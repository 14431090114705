import styled from 'styled-components';
import ReactQuill from 'react-quill';

export const StyledReactQuill = styled(ReactQuill)`
  height: 10rem;
`;

export const StyledWrapper = styled.span`
  white-space: pre-wrap;
  background: none;
`;
