import { toast } from 'react-toastify';

export enum toastIds {
  error = 'error',
  success = 'success',
  failure = 'failure',
  sitemapItemDeleteMock = 'sitemapItemDeleteMock',
  setSelectedEventFailure = 'setSelectedEventFailure',
}

const handleToastNotificationText = (
  notification: toastIds,
  error?: string,
  sitemapItem?: string
) => {
  switch (notification) {
    case toastIds.error:
      return `Conference Publishing Failed With ${error}, Please Try Again`;
    case toastIds.failure:
      return 'Conference Publishing Failed, Please Try Again';
    case toastIds.success:
      return 'Conference Successfully Published';
    case toastIds.sitemapItemDeleteMock:
      return `${sitemapItem} Deleted Successfully`;
    case toastIds.setSelectedEventFailure:
      return 'Event could not be selected, please try again';
    default:
      return null;
  }
};

export const callToastNotification = (
  notificationId: toastIds,
  error?: string,
  sitemapItem?: string
) => {
  return toast(handleToastNotificationText(notificationId, error, sitemapItem), {
    position: toast.POSITION.TOP_CENTER,
    toastId: notificationId,
  });
};
