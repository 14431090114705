import {
  Action,
  LocationDetailsType,
  LocationMetaDataActionTypes,
  LocationMetaDataType,
} from '@/store/types';

function LocationMetaDataReducer(
  state: LocationMetaDataType,
  action: Action
): LocationMetaDataType {
  switch (action.type) {
    case LocationMetaDataActionTypes.setLocationDetails: {
      return {
        ...state,
        locationMetaData: {
          ...state.locationMetaData,
          ...(action.payload as LocationDetailsType),
        },
      };
    }
    default:
      return state;
  }
}

export { LocationMetaDataReducer };
