import React, { Dispatch, useContext, useState } from 'react';
import { Button, Grid } from '@mui/material';
import SitemapItem from '@adminComponents/Sidebar/components/SitemapItem';
import AddIcon from '@mui/icons-material/Add';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import AddSitemapItemModal from '@adminComponents/Sidebar/components/AddSitemapItemModal';
import { sitemapItemsDefault, SupportedViews } from '@/utils/editorViewMap';
import './style.css';
import { SitemapItemsActionTypes, SitemapItemType } from '@/store/types';
import { SitemapItemsContext } from '@/store/context/SitemapItemsContext';

export interface SitemapMenuProps {
  setSelectedEditor: Dispatch<React.SetStateAction<SupportedViews>>;
  sitemapItems: SitemapItemType[];
}

const SitemapMenu = ({ setSelectedEditor, sitemapItems }: SitemapMenuProps) => {
  const { sitemapItemsDispatch } = useContext(SitemapItemsContext);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const handleDragAndDrop = (results: DropResult) => {
    const { source, destination, type } = results;
    if (!destination) return;
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }
    if (type === 'group') {
      const reorderedStores = [...sitemapItems];
      const storeSourceIndex = source.index;
      const storeDestinationIndex = destination.index;
      const [removedStore] = reorderedStores.splice(storeSourceIndex, 1);
      reorderedStores.splice(storeDestinationIndex, 0, removedStore);
      return sitemapItemsDispatch({
        type: SitemapItemsActionTypes.setSitemapItemsDragAndDrop,
        payload: reorderedStores,
      });
    }
  };
  return (
    <Grid style={{ paddingLeft: '1.4rem' }}>
      <DragDropContext onDragEnd={handleDragAndDrop}>
        <Droppable droppableId="ROOT" type="group">
          {(provided) => (
            <Grid {...provided.droppableProps} ref={provided.innerRef}>
              {sitemapItems?.map(({ text, supportedView, id }, index) => (
                <Draggable draggableId={id} key={id} index={index}>
                  {(provided) => (
                    <Grid
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                      ref={provided.innerRef}
                      className="sitemapItem-container"
                    >
                      <SitemapItem
                        key={`${index}: ${text}`}
                        text={text}
                        supportedView={supportedView}
                        setSelectedEditor={setSelectedEditor}
                        id={id}
                      />
                    </Grid>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Grid>
          )}
        </Droppable>
      </DragDropContext>
      <Button
        variant="sitemapItem"
        disableRipple
        onClick={() => setOpenModal(!openModal)}
        startIcon={<AddIcon />}
        size="small"
        title="Add Pages"
        aria-label="Add Pages Button"
        sx={{
          justifyContent: 'flex-start',
          borderBottom: 'none',
        }}
      >
        Add Pages
      </Button>
      <AddSitemapItemModal
        sitemapItems={sitemapItemsDefault}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
    </Grid>
  );
};
export default SitemapMenu;
