import { Action, ImprintActionTypes, ImprintStateType } from '@/store/types';

function ImprintReducer(state: ImprintStateType, action: Action): ImprintStateType {
  switch (action.type) {
    case ImprintActionTypes.setImprint:
      return {
        ...state,
        imprint: action.payload,
      };
    default:
      return state;
  }
}

export { ImprintReducer };
