import axios from '@/api/axios';
import { AppStateType } from '@/store/types';

export async function fetchAllTagungsAppConfigs() {
  const response = await axios.get<AppStateType[]>('');
  return response.data;
}

export async function createNewTagungsAppConfig(config: AppStateType) {
  const response = await axios.post<AppStateType>('', config);
  return response?.data;
}

export async function createSummarize(config: AppStateType) {
  const response = await axios.post('', config, {
    params: {
      summarize: 'true',
    },
  });
  return response?.data;
}
