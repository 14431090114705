import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { Theme } from '@mui/system';
import { Colors } from '@styles';

export const inputBase: ComponentsOverrides<Theme>['MuiInputBase'] = {
  root: {
    '&.MuiInput-root.Mui-disabled': {
      ':before': {
        borderBottom: `1px solid ${Colors.nobel}`,
      },
    },
  },
};
