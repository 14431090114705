import styled from 'styled-components';
import { BordersRadius, boxShadows, Colors, FontSizes } from '@styles';

export const StyledCard = styled.div<{ hasicon: boolean; background?: string }>`
  position: relative;
  padding: 1.3rem 1.6rem;
  display: flex;
  flex-wrap: ${({ hasicon }) => (hasicon ? 'nowrap' : 'wrap')};
  align-items: center;
  row-gap: 0.5rem;
  width: 100%;
  background: ${Colors.white};
  box-shadow: ${({ background }) =>
    background === 'office' ? boxShadows.office : boxShadows.main};
  border-radius: ${BordersRadius.locationCardRadius};
  margin-bottom: 1.3rem;
  color: ${Colors.matisse};
  font-size: ${FontSizes.xl};

  p {
    margin: 0;
    width: 100%;
  }

  > p {
    width: ${({ hasicon }) => (hasicon ? 'initial' : '100%')};
  }
`;

export const StyledImageContainer = styled.div`
  height: 10rem;
  width: 100%;
  position: relative;
  margin: 1.25rem -1.6rem;
  width: calc(100% + 3.1rem);

  > span {
    width: 100% !important;
    height: 100% !important;
  }

  > img {
    width: 100%;
    height: 100%;
    object-position: 50% 90%;
  }
`;

export const StyledIconImage = styled.img`
  margin-right: 1rem;
`;

export const StyledImage = styled.img`
  width: 6.2rem;
  height: 6.2rem;
`;
