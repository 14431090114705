import { ComponentsPropsList } from '@mui/material/styles/props';
import { Interpolation } from '@mui/system';
import { Theme } from '@mui/material/styles/createTheme';
import { Colors, FontFamilies, FontSizes } from '@/styles/styles';

export const sitemapItemButton: {
  props: Partial<ComponentsPropsList['MuiButton']>;
  style: Interpolation<{ theme: Theme }>;
} = {
  props: { variant: 'sitemapItem' },
  style: {
    width: '10rem',
    height: '1rem',
    padding: '0 0 0.6rem 0',
    fontFamily: FontFamilies.golos,
    marginBottom: '1rem',
    fontSize: FontSizes.xs,
    textTransform: 'none',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid',
    color: Colors.nobel,
    borderRadius: '0',
    '&:hover': {
      background: 'transparent',
    },
    '&:focus .MuiButton-endIcon': {
      background: 'transparent',
    },
    '& .MuiButton-startIcon': { transform: 'scale(0.5)', marginRight: '-0px' },
    '& .MuiButton-endIcon': {
      transform: 'scale(0.5)',
      marginLeft: 'auto',
    },
  },
};
