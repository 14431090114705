import React from 'react';
import { createMarkup } from '@/utils/createMarkup';
import { StyledWrapper } from '@/components/Editor/styles';
import { PreviewProps } from './types';

function Interpreter({ value }: PreviewProps) {
  return <StyledWrapper dangerouslySetInnerHTML={createMarkup(value)} />;
}

export default Interpreter;
