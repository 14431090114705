import styled from 'styled-components';
import { styled as MuiStyled } from '@mui/system';
import { Grid } from '@mui/material';
import { boxShadows, Colors } from '@styles';

export const Container = MuiStyled(Grid)({
  position: 'fixed',
  width: '14rem',
  height: '100vh',
  overflowY: 'auto',
  backgroundColor: `${Colors.white}`,
  boxShadow: `${boxShadows.drawer}`,
  padding: '4rem 1rem',
  zIndex: '10',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

export const InnerContainer = MuiStyled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100%',
});

export const OEVLogo = styled.img`
  width: 12rem;
  height: 7rem;
  margin-bottom: 4rem;
`;
export const AdminArea = MuiStyled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '100%',
  height: 'auto',
  marginBottom: '4rem',
});

export const ConfigurationArea = MuiStyled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '100%',
  marginBottom: '3rem',
  flex: 1,
});
