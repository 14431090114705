import React, { useState } from 'react';
import { Grid } from '@mui/material';
import Sidebar from '@adminComponents/Sidebar';
import { SupportedViews, viewMap, ViewType } from '@/utils/editorViewMap';
import { SupportedThemes, themesMap } from '@/utils/themes';
import WithContextProviders from '@/providers/WithContextProviders';
import PopUpModal from '@/components/SummarizeModal';

function Admin() {
  const [invitationString, setInvitationString] = useState<string>('');
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedEditorComponent, setSelectedEditorComponent] = useState(SupportedViews.intro);
  const closeModalHandler = () => setOpenModal(false);
  const { Component } = (viewMap.get(selectedEditorComponent) as ViewType) ?? null;
  const berlinTheme = themesMap.get(SupportedThemes.BERLIN);
  return (
    <WithContextProviders>
      <Grid container>
        <Grid item xs={2}>
          <Sidebar
            setInvitationString={setInvitationString}
            setOpenModal={setOpenModal}
            setSelectedEditor={setSelectedEditorComponent}
          />
        </Grid>
        <Grid item xs={10} p={10}>
          <PopUpModal open={openModal} handleClose={closeModalHandler} text={invitationString} />
          {Component && <Component defaultTheme={berlinTheme} />}
        </Grid>
      </Grid>
    </WithContextProviders>
  );
}

export default Admin;
