import { Grid } from '@mui/material';
import React, { useContext } from 'react';
import Editor from '@components/Editor';
import ContainerComponent from '@components/ContainerComponent';
import Header from '@components/Header';
import { EventNameAndThemeContext, IntroContext } from '@/store/context';
import { IntroActionTypes } from '@/store/types';
import { ComponentViewProps } from '@/pages/admin/views/interfaces';

export default function Intro({ defaultTheme }: ComponentViewProps) {
  const { introEditor, introDispatch } = useContext(IntroContext);
  const { eventNameAndThemeEditor } = useContext(EventNameAndThemeContext);
  return (
    <Grid container>
      <Grid item xs={6}>
        <Editor
          area-label="Intro Editor"
          label="Intro"
          value={introEditor.intro}
          setValue={(val: string) =>
            introDispatch({ type: IntroActionTypes.setIntro, payload: val })
          }
        />
      </Grid>
      <Grid item xs={6}>
        <ContainerComponent
          area-label="Intro View"
          background={eventNameAndThemeEditor?.background?.name}
          id="first"
          image={eventNameAndThemeEditor?.background?.firstPage || defaultTheme?.firstPage}
          header={<Header />}
          footer={{ data: introEditor?.intro, xs: 1 }}
        />
      </Grid>
    </Grid>
  );
}
