import React from 'react';
import { Grid } from '@mui/material';
import { StyledImage } from './styles';
import berlinThemeArrow from '@/assets/background/berlin/arrow.svg';
import officeThemeArrow from '@/assets/background/office/Icon feather-arrow-down-circle.svg';
import { RedirectButtonProps } from './types';

export default function RedirectButton({ redirect, background }: RedirectButtonProps) {
  return (
    <Grid
      container
      item
      xs={12}
      style={{ justifyContent: 'space-between' }}
      aria-label="Navigation Button"
    >
      <StyledImage
        src={background === 'office' ? officeThemeArrow : berlinThemeArrow}
        onClick={() => redirect('second')}
      />
    </Grid>
  );
}
