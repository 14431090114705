import React from 'react';
import { CssBaseline } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import { routes } from '@/routes';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Outlet />}>
            {routes}
          </Route>
        </Routes>
      </BrowserRouter>
    </StyledEngineProvider>
  );
}

export default App;
