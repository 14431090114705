import { Grid } from '@mui/material';
import React, { useContext } from 'react';
import LocationDetailsComponent from '@adminComponents/Sidebar/components/LocationDetails';
import ContainerComponent from '@components/ContainerComponent';
import LocationCard from '@components/LocationCard';
import { EventNameAndThemeContext, LocationMetaDataContext } from '@/store/context';
import { ComponentViewProps } from '@/pages/admin/views/interfaces';

export default function LocationDetails({ defaultTheme }: ComponentViewProps) {
  const { eventNameAndThemeEditor } = useContext(EventNameAndThemeContext);
  const { locationMetaDataEditor, locationMetaDataDispatch } = useContext(LocationMetaDataContext);
  return (
    <Grid container>
      <Grid item xs={6}>
        <LocationDetailsComponent
          label="Location Details"
          value={locationMetaDataEditor.locationMetaData}
          setValue={locationMetaDataDispatch}
          area-label="Location Details Editor"
        />
      </Grid>
      <Grid item xs={6}>
        <ContainerComponent
          background={eventNameAndThemeEditor?.background?.name}
          area-label="Location Details View"
          id="third"
          image={eventNameAndThemeEditor?.background?.thirdPage || defaultTheme?.thirdPage}
          height="100%"
          body={
            <>
              <LocationCard
                background={eventNameAndThemeEditor?.background?.name}
                dateRange={locationMetaDataEditor?.locationMetaData?.dateRange}
              />
              <LocationCard
                background={eventNameAndThemeEditor?.background?.name}
                locationImage={locationMetaDataEditor?.locationMetaData?.locationImage}
                locationName={locationMetaDataEditor?.locationMetaData?.locationName}
              />
              <LocationCard
                background={eventNameAndThemeEditor?.background?.name}
                address={locationMetaDataEditor?.locationMetaData?.address}
              />
            </>
          }
        />
      </Grid>
    </Grid>
  );
}
