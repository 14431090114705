import React, { createContext, ReactNode, useMemo, useReducer } from 'react';
import { Action, SloganStateType } from '@/store/types';
import { SloganReducer } from '@/store/reducer/SloganReducer';

export interface SloganContextProps {
  sloganEditor: SloganStateType;
  sloganDispatch: React.Dispatch<Action>;
}

export const sloganState: SloganStateType = {
  slogan: '',
};

const SloganContext = createContext<SloganContextProps>({
  sloganEditor: sloganState,
  sloganDispatch: () => null,
});

function SloganProvider({ children }: { children: ReactNode }) {
  const [sloganEditor, sloganDispatch] = useReducer(SloganReducer, sloganState);
  const contextValue = useMemo(
    () => ({
      sloganEditor,
      sloganDispatch,
    }),
    [sloganEditor]
  );
  return <SloganContext.Provider value={contextValue}>{children}</SloganContext.Provider>;
}

export { SloganProvider, SloganContext };
