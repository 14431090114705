import React from 'react';
import { Grid } from '@mui/material';
import 'react-quill/dist/quill.snow.css';
import Headlines from '@adminComponents/Sidebar/components/Headlines';
import { StyledReactQuill } from '@/components/Editor/styles';
import { EditorProps } from './types';

export default function Editor({ label, value, setValue }: EditorProps) {
  return (
    <Grid item xs={12}>
      {label && <Headlines>{label}</Headlines>}
      <StyledReactQuill theme="snow" value={value} onChange={setValue} aria-label={label} />
    </Grid>
  );
}
