import React from 'react';
import ReactImageFileToBase64 from 'react-file-image-to-base64';
import { Button, Grid, TextField, Typography } from '@mui/material';
import Headlines from '@adminComponents/Sidebar/components/Headlines';
import { FileType, LocationDetailsProps } from './types';
import Editor from '@/components/Editor';
import { LocationMetaDataActionTypes } from '@/store/types';

export default function LocationDetails({ label, value, setValue }: LocationDetailsProps) {
  const handleOnCompleted = (files: object[]) => {
    const file = files[0] as FileType;
    setValue({
      type: LocationMetaDataActionTypes.setLocationDetails,
      payload: {
        locationImage: {
          contentType: file.file_type,
          name: file.file_name,
          data: file.base64_file.split(',')[1],
        },
      },
    });
  };
  return (
    <Grid
      sx={{
        width: '100%',
        marginBottom: '1rem',
      }}
    >
      <Headlines>{label}</Headlines>
      <Typography>Date: </Typography>
      <TextField
        aria-label="Event Dates Text Input"
        variant="standard"
        value={value.dateRange}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setValue({
            type: LocationMetaDataActionTypes.setLocationDetails,
            payload: {
              dateRange: event.target.value,
            },
          })
        }
      />
      <Typography>Location Ort Name:</Typography>
      <TextField
        variant="standard"
        aria-label="Event Location Text Input"
        value={value.locationName}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setValue({
            type: LocationMetaDataActionTypes.setLocationDetails,
            payload: {
              locationName: event.target.value,
            },
          })
        }
      />
      <Typography>Location Image:</Typography>
      <div>
        <ReactImageFileToBase64 onCompleted={handleOnCompleted} />
      </div>
      <Typography>Delete Image</Typography>
      <div>
        <Button
          aria-label="Delete Location Image"
          onClick={() =>
            setValue({
              type: LocationMetaDataActionTypes.setLocationDetails,
              payload: {
                locationImage: {
                  contentType: '',
                  name: '',
                  data: '',
                },
              },
            })
          }
          variant="contained"
          color="error"
        >
          Delete Image{' '}
        </Button>
      </div>
      <div />
      <Typography>Location Destination</Typography>
      <Editor
        aria-label="Location Destination Editor"
        value={value.address}
        setValue={(val: string) =>
          setValue({
            type: LocationMetaDataActionTypes.setLocationDetails,
            payload: {
              address: val,
            },
          })
        }
      />
    </Grid>
  );
}
