import React, { createContext, ReactNode, useMemo, useReducer } from 'react';
import { createUUID } from '@/utils/createUUID';
import { SupportedViews } from '@/utils/editorViewMap';
import { Action, SitemapItemsStateType } from '@/store/types';
import { SitemapItemsReducer } from '@/store/reducer/SitemapItemsReducer';

export interface SitemapItemsContextProps {
  sitemapItemsEditor: SitemapItemsStateType;
  sitemapItemsDispatch: React.Dispatch<Action>;
}

export const sitemapItemsState: SitemapItemsStateType = {
  sitemapItems: [
    { id: createUUID(), text: 'Intro', supportedView: SupportedViews.intro },
    { id: createUUID(), text: 'Slogan', supportedView: SupportedViews.slogan },
    { id: createUUID(), text: 'Location Details', supportedView: SupportedViews.locationDetails },
    { id: createUUID(), text: 'Agenda', supportedView: SupportedViews.eventDetails },
    { id: createUUID(), text: 'Imprint', supportedView: SupportedViews.imprint },
  ],
};

const SitemapItemsContext = createContext<SitemapItemsContextProps>({
  sitemapItemsEditor: sitemapItemsState,
  sitemapItemsDispatch: () => null,
});

function SitemapItemsProvider({ children }: { children: ReactNode }) {
  const [sitemapItemsEditor, sitemapItemsDispatch] = useReducer(
    SitemapItemsReducer,
    sitemapItemsState
  );
  const contextValue = useMemo(
    () => ({
      sitemapItemsEditor,
      sitemapItemsDispatch,
    }),
    [sitemapItemsEditor]
  );
  return (
    <SitemapItemsContext.Provider value={contextValue}>{children}</SitemapItemsContext.Provider>
  );
}

export { SitemapItemsProvider, SitemapItemsContext };
