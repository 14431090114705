import * as React from 'react';
import { Typography } from '@mui/material';
import { childrenType } from '@/types';

interface HeadlinesProps {
  children?: childrenType;
}

export default function Headlines({ children }: HeadlinesProps) {
  return (
    <Typography variant="h3" style={{ margin: '1rem  0' }}>
      {children}
    </Typography>
  );
}
