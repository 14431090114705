import React from 'react';
import Box from '@mui/material/Box';
import { Modal, Typography } from '@mui/material';
import { createMarkup } from '@/utils/createMarkup';
import { SummarizeModalProps } from '@/components/SummarizeModal';
import { style } from './styles';

// ToDo - refactor
export default function SummarizeModal({ text, open, handleClose }: SummarizeModalProps) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6">
          Summarize
        </Typography>
        <Typography
          mt="2rem"
          id="modal-modal-description"
          dangerouslySetInnerHTML={createMarkup(text.replaceAll('\\n', '<br/>'))}
        />
      </Box>
    </Modal>
  );
}
