import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { Theme } from '@mui/system';
import { Colors } from '@styles';

export const menuItem: ComponentsOverrides<Theme>['MuiMenuItem'] = {
  root: {
    '&:hover': { background: Colors.hawkesBlue },
    '&:focus': { background: Colors.hawkesBlue },
    '&:active': { background: Colors.hawkesBlue },
  },
};
