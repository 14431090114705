import React from 'react';

import routePaths from '@/config/routePaths';
import Landing from '@/pages/landing/Landing';
import Admin from '@/pages/admin/Admin';
import { RouteType } from './types';

function ADMIN() {
  return <Admin />;
}

function TAGUNGSAPP() {
  return <Landing />;
}

const appRoutes: RouteType[] = [
  {
    path: routePaths.ADMIN.path,
    element: <ADMIN />,
    key: 'admind033e22ae',
  },
  {
    path: routePaths.INDEX.path,
    element: <TAGUNGSAPP />,
    key: 'tagung',
  },
  {
    path: routePaths.FALLBACK.path,
    element: <TAGUNGSAPP />,
    key: 'fallback',
  },
];

export default appRoutes;
