import { ComponentsPropsList } from '@mui/material/styles/props';
import { Interpolation } from '@mui/system';
import { Theme } from '@mui/material/styles/createTheme';
import { FontFamilies, FontSizes, fontWeights } from '@/styles/styles';

export const configurationItemButton: {
  props: Partial<ComponentsPropsList['MuiButton']>;
  style: Interpolation<{ theme: Theme }>;
} = {
  props: { variant: 'configurationItem' },
  style: {
    width: '2.3rem',
    height: '1rem',
    fontFamily: FontFamilies.golos,
    fontSize: FontSizes.xs,
    textTransform: 'none',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 0,
    marginBottom: '1rem',
    '&:hover': {
      fontWeight: fontWeights.semiBold,
      background: 'transparent',
    },
    '&:focus .MuiButton-endIcon': {
      background: 'transparent',
    },
  },
};
