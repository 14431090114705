import React, { createContext, ReactNode, useMemo, useReducer } from 'react';
import { Action, ImprintStateType } from '@/store/types';
import { ImprintReducer } from '@/store/reducer/ImprintReducer';

export interface ImprintContextProps {
  imprintEditor: ImprintStateType;
  imprintDispatch: React.Dispatch<Action>;
}

export const imprintState: ImprintStateType = {
  imprint: '',
};

const ImprintContext = createContext<ImprintContextProps>({
  imprintEditor: imprintState,
  imprintDispatch: () => null,
});

function ImprintProvider({ children }: { children: ReactNode }) {
  const [imprintEditor, imprintDispatch] = useReducer(ImprintReducer, imprintState);
  const contextValue = useMemo(
    () => ({
      imprintEditor,
      imprintDispatch,
    }),
    [imprintEditor]
  );
  return <ImprintContext.Provider value={contextValue}>{children}</ImprintContext.Provider>;
}

export { ImprintProvider, ImprintContext };
