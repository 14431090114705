import { useContext } from 'react';
import {
  AgendaContext,
  EventNameAndThemeContext,
  ImprintContext,
  IntroContext,
  LocationMetaDataContext,
  SloganContext,
} from '@/store/context';
import { AppStateType } from '@/store/types';
import { SitemapItemsContext } from '@/store/context/SitemapItemsContext';

export const useCombinedContexts = () => {
  const { agendaEditor } = useContext(AgendaContext);
  const { locationMetaDataEditor } = useContext(LocationMetaDataContext);
  const { imprintEditor } = useContext(ImprintContext);
  const { introEditor } = useContext(IntroContext);
  const { sloganEditor } = useContext(SloganContext);
  const { eventNameAndThemeEditor } = useContext(EventNameAndThemeContext);
  const { sitemapItemsEditor } = useContext(SitemapItemsContext);
  const event: AppStateType = {
    ...agendaEditor,
    ...locationMetaDataEditor,
    ...imprintEditor,
    ...introEditor,
    ...sloganEditor,
    ...sitemapItemsEditor,
    background: eventNameAndThemeEditor.background,
    eventName: eventNameAndThemeEditor.eventName,
  };
  return event;
};
