import React, { Dispatch, useContext, useState } from 'react';
import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { style } from './styles';
import { AddSitemapItemPayloadType, SitemapItemsActionTypes, SitemapItemType } from '@/store/types';
import { SitemapItemsContext } from '@/store/context/SitemapItemsContext';

export interface AddSitemapItemModalProps {
  openModal: boolean;
  setOpenModal: Dispatch<React.SetStateAction<boolean>>;
  sitemapItems: SitemapItemType[];
}

const AddSitemapItemModal = ({
  openModal,
  setOpenModal,
  sitemapItems,
}: AddSitemapItemModalProps) => {
  const { sitemapItemsDispatch } = useContext(SitemapItemsContext);
  const [selectedSupportedView, setSelectedSupportedView] = useState<string>('');
  const [newSitemapItemName, setNewSitemapItemName] = useState<string>('');
  const addSitemapItemHandler = () => {
    if (!newSitemapItemName) return;

    sitemapItemsDispatch({
      type: SitemapItemsActionTypes.addSitemapItem,
      payload: {
        text: newSitemapItemName,
        supportedView: selectedSupportedView,
      } as AddSitemapItemPayloadType,
    });
    setNewSitemapItemName('');
  };
  return (
    <Modal
      open={openModal}
      onClose={() => setOpenModal(!openModal)}
      aria-describedby="a modal pop up for adding a new sitemap item"
    >
      <Grid sx={style}>
        <Typography variant="smallBoldTitle" component="div">
          Add A New Sitemap Item
        </Typography>
        <FormControl>
          <Select
            aria-label="Choose a sitemap item type"
            labelId="select"
            value={selectedSupportedView}
            onChange={(e) => {
              setSelectedSupportedView(e.target.value);
            }}
          >
            {sitemapItems?.map(({ id, supportedView, text }: SitemapItemType) => (
              <MenuItem key={id} value={supportedView}>
                {text}
              </MenuItem>
            ))}
            <MenuItem value="">
              <Typography component="span">Choose page type</Typography>
            </MenuItem>
          </Select>
        </FormControl>
        <TextField
          aria-label="New Page Name"
          style={{ marginTop: '1rem' }}
          variant="standard"
          value={newSitemapItemName}
          placeholder="New Page Name"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setNewSitemapItemName(e.target.value)
          }
        />
        <Button
          style={{ marginTop: '1rem' }}
          variant="contained"
          color="primary"
          title="Add Event Page"
          onClick={() => {
            addSitemapItemHandler();
            setOpenModal(!openModal);
          }}
        >
          Add
        </Button>
      </Grid>
    </Modal>
  );
};
export default AddSitemapItemModal;
