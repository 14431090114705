import React, { ReactNode } from 'react';
import { Route } from 'react-router-dom';
import { RouteType } from './types';
import appRoutes from './appRoutes';

const generateRoutes = (routes: RouteType[]): ReactNode =>
  routes.map((route) => (
    <Route index path={route.path} element={<div>{route.element}</div>} key={route.key} />
  ));

export const routes: ReactNode = generateRoutes(appRoutes);
