import { Colors } from '@styles';

export const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: Colors.white,
  borderRadius: '1rem',
  boxShadow: 24,
  padding: '2rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};
