import React, { useContext, useState } from 'react';
import { Typography } from '@mui/material';
import Configuration from '@adminComponents/Sidebar/views/Configuration';
import SitemapMenu from '@adminComponents/Sidebar/views/SitemapMenu';
import SidebarSubmitPanel from '@adminComponents/Sidebar/views/SidebarSubmitPanel';
import { AdminArea, ConfigurationArea, Container, InnerContainer, OEVLogo } from './styles';
import { SidebarProps } from './types';
import { OevLogo } from '@/assets/icons/Icons';
import ConfigurationBar from '@/pages/admin/components/Sidebar/views/ConfigurationBar';
import { EventNameAndThemeContext } from '@/store/context';
import { AppStateType } from '@/store/types';
import { SitemapItemsContext } from '@/store/context/SitemapItemsContext';

export default function Sidebar({
  setInvitationString,
  setOpenModal,
  setSelectedEditor,
}: SidebarProps) {
  const { eventNameAndThemeEditor, eventNameAndThemeDispatch } =
    useContext(EventNameAndThemeContext);
  const { sitemapItemsEditor } = useContext(SitemapItemsContext);
  const [allEvents, setAllEvents] = useState<AppStateType[]>([]);
  return (
    <Container>
      <InnerContainer>
        <OEVLogo src={OevLogo} alt="oev" />
        <AdminArea>
          <Typography component="div" variant="smallBoldTitle">
            Admin Area
          </Typography>
          <Configuration
            editor={eventNameAndThemeEditor}
            dispatch={eventNameAndThemeDispatch}
            setAllEvents={setAllEvents}
            allEvents={allEvents}
          />
        </AdminArea>
        <ConfigurationArea>
          <ConfigurationBar />
          <SitemapMenu
            setSelectedEditor={setSelectedEditor}
            sitemapItems={sitemapItemsEditor?.sitemapItems}
          />
        </ConfigurationArea>
        <SidebarSubmitPanel
          setInvitationString={setInvitationString}
          setOpenModal={setOpenModal}
          setAllEvents={setAllEvents}
        />
      </InnerContainer>
    </Container>
  );
}
