import { Colors } from '@styles';

export const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: `2px solid ${Colors.black}`,
  boxShadow: 24,
  p: 4,
};
