import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import { theme } from '@/config/theme';
import { EventNameAndThemeProvider } from '@/store/context';
import { ChildrenType } from '@/store/types';

const WithProviders: React.FC<ChildrenType> = function ({ children }) {
  return (
    <EventNameAndThemeProvider>
      <ThemeProvider theme={theme}>
        <ToastContainer />
        {children}
      </ThemeProvider>
    </EventNameAndThemeProvider>
  );
};

export default WithProviders;
