import React from 'react';
import Intro from '@/pages/admin/views/Intro';
import { ImagesType, SitemapItemType } from '@/store/types';
import Slogan from '@/pages/admin/views/Slogan';
import LocationDetails from '@/pages/admin/views/LocationDetails';
import Agenda from '@/pages/admin/views/Agenda';
import Imprint from '@/pages/admin/views/Imprint';
import { createUUID } from '@/utils/createUUID';

export enum SupportedViews {
  intro = 'intro',
  slogan = 'slogan',
  locationDetails = 'locationDetails',
  eventDetails = 'eventDetails',
  imprint = 'imprint',
}

export type ViewType = {
  Component: React.FC<{ defaultTheme: ImagesType | undefined }>;
};
export const viewMap = new Map<SupportedViews, ViewType>([
  [SupportedViews.intro, { Component: Intro }],
  [SupportedViews.slogan, { Component: Slogan }],
  [SupportedViews.locationDetails, { Component: LocationDetails }],
  [SupportedViews.eventDetails, { Component: Agenda }],
  [SupportedViews.imprint, { Component: Imprint }],
]);

export const sitemapItemsDefault: SitemapItemType[] = [
  { id: createUUID(), text: 'Intro', supportedView: SupportedViews.intro },
  { id: createUUID(), text: 'Slogan', supportedView: SupportedViews.slogan },
  { id: createUUID(), text: 'Location Details', supportedView: SupportedViews.locationDetails },
  { id: createUUID(), text: 'Agenda', supportedView: SupportedViews.eventDetails },
  { id: createUUID(), text: 'Imprint', supportedView: SupportedViews.imprint },
];
