import React, { createContext, ReactNode, useMemo, useReducer } from 'react';
import { Action, AgendaType } from '@/store/types';
import { AgendaReducer } from '@/store/reducer/AgendaReducer';
import { createUUID } from '@/utils/createUUID';

export interface AgendaContextProps {
  agendaEditor: AgendaType;
  agendaDispatch: React.Dispatch<Action>;
}

export const agendaState: AgendaType = {
  agenda: [
    {
      date: '',
      talks: [{ uuid: createUUID(), content: '' }],
      description: '',
      uuid: createUUID(),
      currentDayPosition: 0,
    },
  ],
};

const AgendaContext = createContext<AgendaContextProps>({
  agendaEditor: agendaState,
  agendaDispatch: () => null,
});

function AgendaProvider({ children }: { children: ReactNode }) {
  const [agendaEditor, agendaDispatch] = useReducer(AgendaReducer, agendaState);
  const contextValue = useMemo(
    () => ({
      agendaEditor,
      agendaDispatch,
    }),
    [agendaEditor]
  );
  return <AgendaContext.Provider value={contextValue}>{children}</AgendaContext.Provider>;
}

export { AgendaProvider, AgendaContext };
