import {
  Action,
  AddDayActionType,
  AgendaActionTypes,
  AgendaType,
  DayType,
  EventTalkType,
  RemoveDayType,
  RemoveTalkType,
  SetDayActionType,
} from '@/store/types';
import { createUUID } from '@/utils/createUUID';

function AgendaReducer(state: AgendaType, action: Action): AgendaType {
  switch (action.type) {
    case AgendaActionTypes.setAgenda:
      return {
        ...state,
        agenda: action.payload as DayType[],
      };
    case AgendaActionTypes.setDay: {
      const { currentDayPosition } = action.payload as DayType;
      const dayAction = action as SetDayActionType;
      state.agenda[currentDayPosition] = {
        ...state.agenda[currentDayPosition],
        ...dayAction.payload,
      };
      return {
        ...state,
        agenda: state.agenda,
      };
    }
    case AgendaActionTypes.addDay: {
      const dayAction = action as AddDayActionType;
      const newDay = {
        ...state.agenda[0],
        ...dayAction.payload,
        currentEventPosition: state.agenda.length,
        uuid: createUUID(),
      };
      return {
        ...state,
        agenda: [...state.agenda, newDay],
      };
    }
    case AgendaActionTypes.removeDay: {
      const { uuid } = action.payload as RemoveDayType;
      const updatedEventDays = state.agenda.filter((currentDay) => currentDay.uuid !== uuid);
      const newState = state;
      newState.agenda = updatedEventDays;
      return {
        ...state,
        agenda: [...newState.agenda],
      };
    }
    case AgendaActionTypes.addDayTalk: {
      const updatedState = state.agenda.map((day) =>
        day.uuid === (action.payload as EventTalkType).uuid
          ? { ...day, ...(action.payload as EventTalkType) }
          : day
      );
      return {
        ...state,
        agenda: [...updatedState],
      };
    }
    case AgendaActionTypes.setEventTalk: {
      const { currentDay, content, uuid } = action.payload as EventTalkType;
      const currentTalk = currentDay.talks.find((talk) => talk.uuid === uuid);
      if (!currentTalk) return { ...state };
      currentTalk.content = content;

      const newCurrentEvent = currentDay;
      newCurrentEvent.talks = currentDay.talks.map((talk) =>
        talk.uuid === currentTalk.uuid ? { ...talk, ...currentTalk } : talk
      );
      const newState = state;
      const indexOfEvent = state.agenda.findIndex((day) => day.uuid === currentDay.uuid);
      newState.agenda[indexOfEvent] = newCurrentEvent;
      return { ...state, agenda: newState.agenda };
    }
    case AgendaActionTypes.removeTalk: {
      const { currentDay, talk } = action.payload as RemoveTalkType;
      const newCurrentDay = currentDay;
      newCurrentDay.talks = currentDay.talks.filter((currentDay) => currentDay.uuid !== talk.uuid);
      const newState = state;
      const indexOfEvent = state.agenda.findIndex((item) => item.uuid === currentDay.uuid);
      newState.agenda[indexOfEvent] = newCurrentDay;
      return { ...state, agenda: newState.agenda };
    }
    default:
      return state;
  }
}

export { AgendaReducer };
