import React, { createContext, ReactNode, useMemo, useReducer } from 'react';
import { Action, IntroStateType } from '@/store/types';
import { IntroReducer } from '@/store/reducer/IntroReducer';

export interface IntroContextProps {
  introEditor: IntroStateType;
  introDispatch: React.Dispatch<Action>;
}

export const introState: IntroStateType = {
  intro: '',
};

const IntroContext = createContext<IntroContextProps>({
  introEditor: introState,
  introDispatch: () => null,
});

function IntroProvider({ children }: { children: ReactNode }) {
  const [introEditor, introDispatch] = useReducer(IntroReducer, introState);
  const contextValue = useMemo(
    () => ({
      introEditor,
      introDispatch,
    }),
    [introEditor]
  );
  return <IntroContext.Provider value={contextValue}>{children}</IntroContext.Provider>;
}

export { IntroProvider, IntroContext };
