import { createTheme } from '@mui/material/styles';
import React from 'react';
import {
  button,
  fromControl,
  inputBase,
  menu,
  menuItem,
  select,
  textField,
} from '@themeStyles/styleOverrides';
import { configurationItemButton, sitemapItemButton } from '@themeStyles/buttonVariants';
import { Colors, FontSizes, fontWeights } from '@styles';
import '@/styles/fonts/GolosText-SemiBold.ttf';
import '@/styles/fonts/GolosText-Regular.ttf';
import '@/styles/fonts/GolosText-Bold.ttf';
import '@/styles/fonts/GolosText-ExtraBold.ttf';
import '@/styles/fonts/GolosText-Medium.ttf';
import '@/styles/fonts/GolosText-Black.ttf';

const fontFamily = ['golos', 'Roboto', 'Arial'].join(',');
const fontWeight = fontWeights.normal;
const fontSize = FontSizes.xs;

declare module '@mui/material/styles' {
  interface TypographyVariants {
    officeTheme: React.CSSProperties;
    berlinAndTech: React.CSSProperties;
    imprintParagraph: React.CSSProperties;
    imprintOfficeParagraph?: React.CSSProperties;
    talkCardParagraph?: React.CSSProperties;
    locationName?: React.CSSProperties;
    smallBoldTitle?: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    officeTheme?: React.CSSProperties;
    berlinAndTechTheme?: React.CSSProperties;
    imprintParagraph?: React.CSSProperties;
    imprintOfficeParagraph?: React.CSSProperties;
    talkCardParagraph?: React.CSSProperties;
    locationName?: React.CSSProperties;
    smallBoldTitle?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    officeTheme: true;
    berlinAndTechTheme: true;
    imprintParagraph: true;
    imprintOfficeParagraph: true;
    talkCardParagraph: true;
    locationName: true;
    smallBoldTitle: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    configurationItem: true;
    sitemapItem: true;
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: Colors.denim,
      dark: Colors.atoll,
      light: Colors.nobel,
    },
    secondary: {
      main: Colors.regentGray,
      dark: Colors.slateGray,
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: inputBase,
    },
    MuiButton: {
      variants: [configurationItemButton, sitemapItemButton],
      styleOverrides: button,
    },
    MuiSelect: {
      defaultProps: {
        displayEmpty: true,
      },
      styleOverrides: select,
    },
    MuiFormControl: {
      defaultProps: {
        focused: false,
      },
      styleOverrides: fromControl,
    },
    MuiTextField: {
      defaultProps: {
        InputProps: {
          disableUnderline: true,
        },
      },
      styleOverrides: textField,
    },
    MuiMenuItem: {
      defaultProps: { selected: true, disableGutters: false, dense: false },
      styleOverrides: menuItem,
    },
    MuiMenu: {
      styleOverrides: menu,
    },
  },
  typography: {
    allVariants: {
      fontFamily,
      fontWeight,
      fontSize,
    },
    h1: {
      fontSize: FontSizes.sixxl,
    },
    h2: {
      fontSize: FontSizes.fivexl,
      color: Colors.matisse,
      fontWeight: fontWeights.stringBold,
    },
    h3: {
      fontSize: FontSizes.xxxl,
      color: Colors.matisse,
    },
    h4: {
      fontSize: FontSizes.xl,
      color: Colors.matisse,
    },
    h5: {
      fontSize: FontSizes.md,
    },
    h6: {
      fontSize: FontSizes.sm,
    },
    officeTheme: {
      fontSize: FontSizes.fivexl,
      color: Colors.black,
      fontWeight: fontWeights.stringBold,
    },
    berlinAndTechTheme: {
      fontSize: FontSizes.fivexl,
      color: Colors.white,
      fontWeight: fontWeights.stringBold,
    },
    imprintParagraph: {
      fontSize: FontSizes.md,
      color: Colors.white,
    },
    imprintOfficeParagraph: {
      fontSize: FontSizes.md,
      color: Colors.black,
    },
    talkCardParagraph: {
      fontSize: FontSizes.xl,
      color: Colors.black,
    },
    locationName: {
      fontSize: FontSizes.fivexl,
      color: Colors.black,
      fontWeight: fontWeights.stringBold,
    },
    smallBoldTitle: {
      fontSize: FontSizes.sm,
      fontWeight: fontWeights.semiBold,
      marginBottom: '1rem',
    },
  },
});
