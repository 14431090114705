import {
  Action,
  AddSitemapItemPayloadType,
  SitemapItemsActionTypes,
  SitemapItemsStateType,
  SitemapItemType,
} from '@/store/types';
import { createUUID } from '@/utils/createUUID';

function SitemapItemsReducer(state: SitemapItemsStateType, action: Action): SitemapItemsStateType {
  switch (action.type) {
    case SitemapItemsActionTypes.setSitemapItemsDragAndDrop: {
      const updatedSitemapItems = action.payload as SitemapItemType[];
      return {
        ...state,
        sitemapItems: updatedSitemapItems,
      };
    }
    case SitemapItemsActionTypes.addSitemapItem: {
      const { text, supportedView } = action.payload as AddSitemapItemPayloadType;
      const newItem = {
        id: createUUID(),
        text,
        supportedView,
      };
      return {
        ...state,
        sitemapItems: [...state.sitemapItems, newItem as SitemapItemType],
      };
    }
    case SitemapItemsActionTypes.removeSitemapItem: {
      const id = action.payload;
      const updatedSitemapItems = state.sitemapItems.filter((item) => item.id !== id);
      return {
        ...state,
        sitemapItems: updatedSitemapItems,
      };
    }
    default:
      return state;
  }
}

export { SitemapItemsReducer };
