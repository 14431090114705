const routePaths = {
  INDEX: {
    path: '/',
  },
  FALLBACK: {
    path: '/*',
  },
  ADMIN: {
    path: '/admin',
  },
};

export default routePaths;
