import { Action, IntroActionTypes, IntroStateType } from '@/store/types';

function IntroReducer(state: IntroStateType, action: Action): IntroStateType {
  switch (action.type) {
    case IntroActionTypes.setIntro: {
      return {
        ...state,
        intro: action.payload,
      };
    }
    default:
      return state;
  }
}

export { IntroReducer };
