import React, { useContext } from 'react';
import { Grid } from '@mui/material';
import Editor from '@components/Editor';
import ContainerComponent from '@components/ContainerComponent';
import { EventNameAndThemeContext, ImprintContext } from '@/store/context';
import Contact from '@/components/Contact';
import { ImprintActionTypes } from '@/store/types';
import { ComponentViewProps } from '@/pages/admin/views/interfaces';

export default function Imprint({ defaultTheme }: ComponentViewProps) {
  const { eventNameAndThemeEditor } = useContext(EventNameAndThemeContext);
  const { imprintEditor, imprintDispatch } = useContext(ImprintContext);
  return (
    <Grid container>
      <Grid item xs={6}>
        <Editor
          area-label="Imprint of the Page Editor"
          label="Imprint of the Page"
          value={imprintEditor.imprint}
          setValue={(val: string) =>
            imprintDispatch({ type: ImprintActionTypes.setImprint, payload: val })
          }
        />
      </Grid>
      <Grid item xs={6}>
        <ContainerComponent
          area-label="Imprint of the Page View"
          background={eventNameAndThemeEditor?.background?.name}
          id="tenth"
          height="100%"
          image={eventNameAndThemeEditor?.background?.fourthPage || defaultTheme?.fourthPage}
          body={
            <Contact
              value={imprintEditor?.imprint || ''}
              background={eventNameAndThemeEditor?.background?.name}
            />
          }
        />
      </Grid>
    </Grid>
  );
}
