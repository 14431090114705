import React, { useContext } from 'react';
import { Button, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Event from '@adminComponents/Sidebar/components/Event';
import EventDetailsComponent from '@adminComponents/Sidebar/components/EventDetails';
import ContainerComponent from '@components/ContainerComponent';
import LocationCard from '@components/LocationCard';
import Card from '@components/Card';
import { AgendaContext, EventNameAndThemeContext } from '@/store/context';
import { Action, AgendaActionTypes, AgendaType, DayType, TalkType } from '@/store/types';
import { StyledWrapper } from '@/pages/admin/styles';
import { createUUID } from '@/utils/createUUID';
import { ComponentViewProps } from '@/pages/admin/views/interfaces';

export default function Agenda({ defaultTheme }: ComponentViewProps) {
  const { eventNameAndThemeEditor } = useContext(EventNameAndThemeContext);
  const { agendaEditor, agendaDispatch } = useContext(AgendaContext);
  const addDay = (dispatchFunc: React.Dispatch<Action>, state: AgendaType) => {
    const newDay: DayType = {
      date: '',
      talks: [],
      description: '',
      currentDayPosition: state?.agenda.length,
      uuid: createUUID(),
    };
    dispatchFunc({
      type: AgendaActionTypes.addDay,
      payload: newDay,
    });
  };
  return (
    <Grid container>
      <Grid item xs={6}>
        {agendaEditor.agenda?.map((day: DayType, currentDayPosition: number) => (
          <Box key={day.uuid} data-testid="event-days-container">
            <StyledWrapper>
              <Event
                aria-label={`Event Details Day ${currentDayPosition + 1}`}
                value={day}
                setValue={agendaDispatch}
                label={`Event Details Day ${currentDayPosition + 1}`}
                uuid={day.uuid}
                currentDayPosition={currentDayPosition}
                data-testid="event-day"
              />
            </StyledWrapper>
            <StyledWrapper>
              <EventDetailsComponent setValue={agendaDispatch} day={day} />
            </StyledWrapper>
          </Box>
        ))}
        <Button
          label-area="Add Event Day"
          variant="contained"
          color="error"
          onClick={() => addDay(agendaDispatch, agendaEditor)}
          data-testid="addDay-button"
        >
          add new day
        </Button>
      </Grid>
      <Grid item xs={6} aria-label="Event Details View">
        {agendaEditor?.agenda?.map((event: DayType) => (
          <div key={event.uuid}>
            <ContainerComponent
              background={eventNameAndThemeEditor?.background?.name}
              id="forth"
              image={eventNameAndThemeEditor?.background?.fourthPage || defaultTheme?.fourthPage}
              footer={{ data: event.description, xs: 1 }}
            />
            <ContainerComponent
              background={eventNameAndThemeEditor?.background?.name}
              id="seventh"
              image={eventNameAndThemeEditor?.background?.fifthPage || defaultTheme?.fifthPage}
              height="100%"
              body={
                <>
                  <LocationCard dateRange={event.date} />
                  {event.talks?.map((talk: TalkType) => (
                    <Card
                      key={talk.uuid}
                      value={talk.content}
                      background={eventNameAndThemeEditor?.background?.name}
                    />
                  ))}
                </>
              }
            />
          </div>
        ))}
      </Grid>
    </Grid>
  );
}
