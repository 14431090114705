import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { Theme } from '@mui/system';
import { BordersRadius, Colors, FontFamilies, FontSizes, fontWeights } from '@styles';

export const button: ComponentsOverrides<Theme>['MuiButton'] = {
  root: {
    borderRadius: BordersRadius.buttonBorderRadius,
    textTransform: 'none',
    minWidth: '9rem',
    height: '2.2rem',
    ':disabled': {
      backgroundColor: Colors.nobel,
      color: Colors.white,
    },
  },
  contained: {
    fontSize: FontSizes.xs,
    color: Colors.white,
    fontFamily: FontFamilies.golos,
  },
  text: {
    fontWeight: fontWeights.light,
  },
  outlined: {
    fontWeight: fontWeights.light,
  },
};
