import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { Theme } from '@mui/system';
import { borders } from '@styles';

export const fromControl: ComponentsOverrides<Theme>['MuiFormControl'] = {
  root: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '0.75rem',
      border: borders.selectBorder,
    },
    '& .MuiOutlinedInput-root': {
      width: '12.3rem',
      height: '2.5rem',
    },
  },
};
