import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import { Colors } from '@styles';

export const Wrapper = styled(Grid)<{
  image?: string;
  background?: string;
  height?: string;
  $isAdminPage: boolean;
}>`
  padding: 1.25rem 2rem;
  width: 100vw;
  min-width: 23rem;
  max-width: 28rem;
  position: relative;
  height: ${({ height }) => height};
  z-index: 1;
  margin: ${({ $isAdminPage }) => ($isAdminPage ? '' : '0 auto')};
  background: ${({ background }) => background || Colors.white};
  ${({ image }) =>
    image &&
    `
      background-image: url(${image});
      background-size: cover;
      background-repeat: no-repeat;
      `}

}
`;

export const InnerWrapper = styled(Grid)`
  justify-content: space-between;
`;

export const StyledContent = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const StyledFooterFirst = styled.div`
  min-height: 10vh;
  text-align: left;
  width: 100%;
`;

export const StyledFooter = styled.div`
  min-height: 80vh;
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-start;
`;

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 30vh;
`;
