export const MDBreakPoint = 640;
export const LGBreakPoint = 1280;

export enum Colors {
  white = '#FFFFFF',
  nobel = '#B5B5B5',
  doveGray = '#636363',
  regentGray = '#8FA0AA',
  slateGray = '#728794',
  aliceBlue = '#EFF7FF',
  powderWhite = '#ECF1F4',
  hawkesBlue = '#D2EDFB',
  balmySeas = '#B2DBD9',
  cornflower = '#9AC5ED',
  denim = '#1078AD',
  atoll = '#0C587E',
  matisse = '#1E4E9A',
  gladolia = '#D84E44',
  jasmine = '#EEE8A9',
  mineShaft = '#2d2d2d',
  blackRussian = '#000029',
  black = '#000000',
}

export enum FontSizes {
  xs = '12px',
  sm = '14px',
  md = '16px',
  lg = '18px',
  xl = '20px',
  xxl = '22px',
  xxxl = '24px',
  fourxl = '28px',
  fivexl = '32px',
  sixxl = '40px',
  sevenxl = '48px',
  eightxl = '54px',
}

export const FontFamilies = {
  golos: 'Golos',
};

export enum BordersRadius {
  buttonBorderRadius = '6.25rem',
  locationCardRadius = '1.25rem 1.25rem',
}

export const fontWeights = {
  extraLight: 100,
  light: 200,
  normal: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  stringLighter: 'lighter',
  stringNormal: 'normal',
  stringBolder: 'bolder',
  stringBold: 'bold',
};

export const borders = {
  textFieldBorder: `solid 1px ${Colors.mineShaft}`,
  textFieldBorderDisabled: `solid 1px ${Colors.nobel}`,
  selectBorder: `0.07rem solid ${Colors.mineShaft}`,
};

export const boxShadows = {
  office: `0px 2px 4px ${Colors.blackRussian} 0.3`,
  main: `0 4px 4px ${Colors.matisse}`,
  accordion: `0px 4px 4px ${Colors.black}`,
  navbar: `0px 2px 8px 0px ${Colors.doveGray}`,
  drawer: `0px 0px 6px ${Colors.doveGray}`,
};
