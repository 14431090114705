import { CreateEmailInvitationParams } from '@/pages/admin/components/Sidebar/types';
import { callToastNotification, toastIds } from '@/utils/toastify';

export const createEmailInvitation = async ({
  createSummarize,
  completedEvent,
  setInvitationString,
  setOpenModal,
}: CreateEmailInvitationParams) => {
  try {
    const response = await createSummarize(completedEvent);
    setInvitationString(response.summary);
    setOpenModal(true);
  } catch (error) {
    if (error instanceof Error) {
      callToastNotification(toastIds.error, error.message);
    }
  }
};
