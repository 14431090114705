import React from 'react';
import { Typography } from '@mui/material';
import { createMarkup } from '@/utils/createMarkup';
import { CardProps } from '@/components/Card';
import { StyledCard } from './styles';

export default function Card({ value, background }: CardProps) {
  return (
    <StyledCard aria-label="Card content">
      <Typography
        variant={background === 'office' ? 'talkCardParagraph' : 'h4'}
        dangerouslySetInnerHTML={createMarkup(value)}
      />
    </StyledCard>
  );
}
