import axios from 'axios';
import { env } from '@/utils/env';

const url = env.REACT_APP_API_URL;
export default axios.create({
  baseURL: `${url}/resources`,
  headers: {
    'Content-type': 'application/json, text/plain, */*',
  },
});
