import React from 'react';
import { Button, Grid } from '@mui/material';
import { SidebarSubmitPanelProps } from '@adminComponents/Sidebar/views/SidebarSubmitPanel/interfaces';
import { createEmailInvitation } from '@/utils/createEmailInvitation';
import {
  createNewTagungsAppConfig,
  createSummarize,
  fetchAllTagungsAppConfigs,
} from '@/api/admin/adminApi';
import { createEventHandler } from '@/utils/createEventHandler';
import { useCombinedContexts } from '@/hooks/useCombinedReducers';

const SidebarSubmitPanel = ({
  setInvitationString,
  setOpenModal,
  setAllEvents,
}: SidebarSubmitPanelProps) => {
  const completedEvent = useCombinedContexts();
  return (
    <Grid container display="flex" direction="column" alignItems="center">
      <Button
        style={{ marginTop: '1rem' }}
        variant="contained"
        color="secondary"
        onClick={() =>
          createEmailInvitation({
            createSummarize,
            completedEvent,
            setInvitationString,
            setOpenModal,
          })
        }
        title="Summarize in a Mail"
        aria-label="Summarize Event Button"
      >
        Summarize In a Mail
      </Button>
      <Button
        style={{ marginTop: '1rem' }}
        variant="contained"
        color="primary"
        title="Publish changes"
        aria-label="Publish Event Button"
        onClick={() =>
          createEventHandler({
            createNewTagungsAppConfig,
            completedEvent,
            setAllEvents,
            fetchAllTagungsAppConfigs,
          })
        }
      >
        Publish Changes
      </Button>
    </Grid>
  );
};
export default SidebarSubmitPanel;
