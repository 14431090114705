import berlinIntro from '@/assets/background/berlin/berlin-intro.webp';
import berlinSlogan from '@/assets/background/berlin/berlin-slogan.webp';
import berlinLocationDetails from '@/assets/background/berlin/berlin-location-details.webp';
import berlinAgenda from '@/assets/background/berlin/berlin-agenda.webp';
import berlinImprint from '@/assets/background/berlin/berlin-imprint.webp';
import officeIntro from '@/assets/background/office/office-intro.webp';
import officeSlogan from '@/assets/background/office/office-slogan.webp';
import officeLocationDetails from '@/assets/background/office/office-locationDetails.webp';
import officeAgenda from '@/assets/background/office/office-agenda.webp';
import officeImprint from '@/assets/background/office/office-imprint.webp';
import technologyIntro from '@/assets/background/technologies/technologie.webp';
import technologySlogan from '@/assets/background/technologies/technologie-intro.webp';
import technologyLocationDetails from '@/assets/background/technologies/technologie-separator.webp';
import technologyAgenda from '@/assets/background/technologies/technologie-agenda.webp';
import technologyImprint from '@/assets/background/technologies/technologie-legal-notice.webp';
import { ImagesType } from '@/store/types';

export enum SupportedThemes {
  BERLIN = 'berlin',
  OFFICE = 'office',
  TECHNOLOGIES = 'technologies',
}

export const themesMap = new Map<SupportedThemes, ImagesType>([
  [
    SupportedThemes.BERLIN,
    {
      name: SupportedThemes.BERLIN,
      firstPage: berlinIntro,
      secondPage: berlinSlogan,
      thirdPage: berlinLocationDetails,
      fourthPage: berlinAgenda,
      fifthPage: berlinImprint,
    },
  ],
  [
    SupportedThemes.OFFICE,
    {
      name: SupportedThemes.OFFICE,
      firstPage: officeIntro,
      secondPage: officeSlogan,
      thirdPage: officeLocationDetails,
      fourthPage: officeAgenda,
      fifthPage: officeImprint,
    },
  ],
  [
    SupportedThemes.TECHNOLOGIES,
    {
      name: SupportedThemes.TECHNOLOGIES,
      firstPage: technologyIntro,
      secondPage: technologySlogan,
      thirdPage: technologyLocationDetails,
      fourthPage: technologyAgenda,
      fifthPage: technologyImprint,
    },
  ],
]);
