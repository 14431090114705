import { Action, SloganActionTypes, SloganStateType } from '@/store/types';

function SloganReducer(state: SloganStateType, action: Action): SloganStateType {
  switch (action.type) {
    case SloganActionTypes.setSlogan:
      return {
        ...state,
        slogan: action.payload,
      };

    default:
      return state;
  }
}

export { SloganReducer };
