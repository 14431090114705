import React, { useContext } from 'react';
import { Button, Grid, Modal, Typography } from '@mui/material';
import { style } from '@adminComponents/Sidebar/components/AddSitemapItemModal/styles';
import { RemoveSitemapItemModalProps } from './interfaces';
import { callToastNotification, toastIds } from '@/utils/toastify';
import { SitemapItemsContext } from '@/store/context/SitemapItemsContext';
import { SitemapItemsActionTypes } from '@/store/types';

const RemoveSitemapItemModal = ({
  openModal,
  setOpenModal,
  id,
  text,
}: RemoveSitemapItemModalProps) => {
  const { sitemapItemsDispatch } = useContext(SitemapItemsContext);
  return (
    <Modal
      open={openModal}
      onClose={() => setOpenModal(!openModal)}
      aria-describedby="a modal popes up to make sure the item should be deleted"
    >
      <Grid sx={style}>
        <Typography variant="smallBoldTitle" component="div">
          Are you sure you want to delete {text} ?
        </Typography>
        <Grid>
          <Button
            style={{ margin: '1rem 1rem 0 0' }}
            variant="contained"
            color="primary"
            title="Add Event Page"
            onClick={() => {
              sitemapItemsDispatch({
                type: SitemapItemsActionTypes.removeSitemapItem,
                payload: id,
              });
              callToastNotification(toastIds.sitemapItemDeleteMock, '', text);
              setOpenModal(!openModal);
            }}
          >
            yes
          </Button>
          <Button
            style={{ marginTop: '1rem' }}
            variant="contained"
            color="primary"
            title="Add Event Page"
            onClick={() => setOpenModal(!openModal)}
          >
            no
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default RemoveSitemapItemModal;
