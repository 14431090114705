import React, { createContext, ReactNode, useMemo, useReducer } from 'react';
import { EventNameAndThemeReducer } from '@/store/reducer/EventNameAndThemeReducer';
import { Action, EventNameAndThemeStateType } from '@/store/types';

export interface EventNameAndThemeProps {
  eventNameAndThemeEditor: EventNameAndThemeStateType;
  eventNameAndThemeDispatch: React.Dispatch<Action>;
}

export const eventNameAndThemeState: EventNameAndThemeStateType = {
  eventName: '',
  background: {
    name: '',
    firstPage: '',
    secondPage: '',
    thirdPage: '',
    fourthPage: '',
    fifthPage: '',
  },
};

const EventNameAndThemeContext = createContext<EventNameAndThemeProps>({
  eventNameAndThemeEditor: eventNameAndThemeState,
  eventNameAndThemeDispatch: () => null,
});

function EventNameAndThemeProvider({ children }: { children: ReactNode }) {
  const [eventNameAndThemeEditor, eventNameAndThemeDispatch] = useReducer(
    EventNameAndThemeReducer,
    eventNameAndThemeState
  );
  const contextValue = useMemo(
    () => ({
      eventNameAndThemeEditor,
      eventNameAndThemeDispatch,
    }),
    [eventNameAndThemeEditor]
  );
  return (
    <EventNameAndThemeContext.Provider value={contextValue}>
      {children}
    </EventNameAndThemeContext.Provider>
  );
}

export { EventNameAndThemeProvider, EventNameAndThemeContext };
