import {
  Action,
  EventNameAndAgendaActionTypes,
  EventNameAndThemeStateType,
  ImagesType,
} from '@/store/types';
import { SupportedThemes, themesMap } from '@/utils/themes';

function EventNameAndThemeReducer(
  state: EventNameAndThemeStateType,
  action: Action
): EventNameAndThemeStateType {
  switch (action.type) {
    case EventNameAndAgendaActionTypes.setEventName:
      return {
        ...state,
        eventName: action.payload,
      };
    case EventNameAndAgendaActionTypes.setBackground: {
      const name = action.payload;
      const THEME = themesMap.get(name as SupportedThemes);
      return {
        ...state,
        background: THEME as ImagesType,
      };
    }
    default:
      return state;
  }
}

export { EventNameAndThemeReducer };
