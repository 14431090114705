import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import WithProviders from '@/providers/WithProviders';
import App from '@/app/App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <WithProviders>
    <App />
  </WithProviders>
);
