import React, { ReactNode } from 'react';
import {
  AgendaProvider,
  ImprintProvider,
  IntroProvider,
  LocationMetaDataProvider,
  SloganProvider,
} from '@/store/context';
import { SitemapItemsProvider } from '@/store/context/SitemapItemsContext';

export enum SupportedProviders {
  LOCATION = 'locationMetaDataProvider',
  SLOGAN = 'sloganProvider',
  INTRO = 'introProvider',
  IMPRINT = 'imprintProvider',
  AGENDA = 'agendaProvider',
  SITEMAP = 'sitemapItemsProvider',
}

export type ProviderType = {
  Provider: React.FC<{ children: ReactNode }>;
};

export const providersMap = new Map<SupportedProviders, ProviderType>([
  [SupportedProviders.LOCATION, { Provider: LocationMetaDataProvider }],
  [SupportedProviders.SLOGAN, { Provider: SloganProvider }],
  [SupportedProviders.INTRO, { Provider: IntroProvider }],
  [SupportedProviders.IMPRINT, { Provider: ImprintProvider }],
  [SupportedProviders.AGENDA, { Provider: AgendaProvider }],
  [SupportedProviders.SITEMAP, { Provider: SitemapItemsProvider }],
]);
