import React from 'react';
import { Typography } from '@mui/material';
import { createMarkup } from '@/utils/createMarkup';
import { LocationDetailsType } from '@/store/types';
import appRoutes from '@/routes/appRoutes';
import Date from '@/assets/favicons/date_19.png';
import {
  StyledCard,
  StyledIconImage,
  StyledImage,
  StyledImageContainer,
} from '@/components/LocationCard/styles';

export default function LocationCard({
  locationName,
  locationImage,
  dateRange,
  address,
  background,
}: LocationDetailsType) {
  return (
    <StyledCard hasicon={!!dateRange} background={background} aria-label="event location details">
      {dateRange && <StyledIconImage width={25} height={25} src={Date} />}
      <Typography
        style={{
          margin: 0,
        }}
        variant={background === 'office' ? 'locationName' : 'h2'}
        dangerouslySetInnerHTML={createMarkup(locationName || '')}
      />

      <div dangerouslySetInnerHTML={createMarkup(dateRange || '')} />
      {locationImage?.data && (
        <StyledImageContainer>
          <StyledImage
            src={
              window.location.pathname.includes(appRoutes[0].key)
                ? `data:${locationImage?.contentType};base64,${locationImage?.data}`
                : encodeURI(locationImage?.data || '')
            }
          />
        </StyledImageContainer>
      )}
      <Typography
        variant={background === 'office' ? 'talkCardParagraph' : 'h4'}
        dangerouslySetInnerHTML={createMarkup(address || '')}
      />
    </StyledCard>
  );
}
