import { CreateEventHandlerParams } from '@/pages/admin/components/Sidebar/types';
import { callToastNotification, toastIds } from '@/utils/toastify';

export const createEventHandler = async ({
  createNewTagungsAppConfig,
  completedEvent,
  setAllEvents,
  fetchAllTagungsAppConfigs,
}: CreateEventHandlerParams) => {
  try {
    await createNewTagungsAppConfig(completedEvent);
    callToastNotification(toastIds.success);

    const newEventsList = await fetchAllTagungsAppConfigs();
    setAllEvents(newEventsList);
  } catch (error) {
    if (error instanceof Error) {
      callToastNotification(toastIds.error, error.message);
    }
  }
};
