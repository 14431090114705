import React from 'react';
import { Grid } from '@mui/material';
import oevLogo from '@/assets/background/berlin/oev_logo.svg';

export default function Header() {
  return (
    <Grid container justifyContent="space-between">
      <img alt="oev logo" src={oevLogo} height={25} width={50} />
    </Grid>
  );
}
