import { ReactNode } from 'react';
import { SupportedViews } from '@/utils/editorViewMap';
import { SupportedThemes } from '@/utils/themes';

// # DATA TYPES #

export type RemoveTalkType = {
  talk: TalkType;
  currentDay: DayType;
};

export type RemoveDayType = {
  uuid: string;
};

export type EventTalkType = {
  uuid: string;
  content: string;
  currentDay: DayType;
};
export type LocationImageType = {
  data: string;
  name: string;
  contentType: string;
};
export type LocationDetailsType = {
  dateRange?: string;
  locationImage?: LocationImageType;
  locationName?: string;
  address?: string;
  background?: string;
};

export type DayType = {
  description: string;
  date: string;
  talks: TalkType[];
  uuid: string;
  currentDayPosition: number;
};

export type TalkType = {
  uuid: string;
  content: string;
};

export type AppStateType = {
  eventName: string;
  intro: string;
  slogan: string;
  background: ImagesType;
  locationMetaData: LocationDetailsType;
  agenda: DayType[];
  imprint: string;
  sitemapItems: SitemapItemType[];
};

export type EventNameAndThemeStateType = {
  eventName: string;
  background: ImagesType;
};

export type ImagesType = {
  name: string;
  firstPage: string;
  secondPage: string;
  thirdPage: string;
  fourthPage: string;
  fifthPage: string;
};

export type SitemapItemType = {
  text: string;
  supportedView: SupportedViews;
  id: string;
};

export type SitemapItemsStateType = {
  sitemapItems: SitemapItemType[];
};

export type ChildrenType = {
  children?: ReactNode;
};

export type ImprintStateType = {
  imprint: string;
};

export type AgendaType = {
  agenda: DayType[];
};

export type IntroStateType = {
  intro: string;
};

export type LocationMetaDataType = {
  locationMetaData: LocationDetailsType;
};

export type SloganStateType = {
  slogan: string;
};

export type AddSitemapItemPayloadType = {
  text: string;
  supportedView: SupportedViews;
};

// # ACTION INTERFACES #

export type SetAgendaActionType = {
  type: AgendaActionTypes.setAgenda;
  payload: DayType[];
};

export type SetDayActionType = {
  type: AgendaActionTypes.setDay;
  payload: Partial<DayType>;
};

export type AddDayActionType = {
  type: AgendaActionTypes.addDay;
  payload: Partial<DayType>;
};

export type AddDayTalkActionType = {
  type: AgendaActionTypes.addDayTalk;
  payload: DayType;
};

export type RemoveTalkActionType = {
  type: AgendaActionTypes.removeTalk;
  payload: RemoveTalkType;
};

export type RemoveDayActionType = {
  type: AgendaActionTypes.removeDay;
  payload: RemoveDayType;
};

export type SetEventTalkActionType = {
  type: AgendaActionTypes.addDayTalk | AgendaActionTypes.setEventTalk;
  payload: EventTalkType;
};

export type SetLocationDetailsActionType = {
  type: LocationMetaDataActionTypes.setLocationDetails;
  payload: LocationDetailsType;
};

export type SetBackgroundType = {
  type: EventNameAndAgendaActionTypes.setBackground;
  payload: SupportedThemes;
};

export type SetEventNameType = {
  type: EventNameAndAgendaActionTypes.setEventName;
  payload: string;
};

export type SetSitemapMenuDragAndDropType = {
  type: SitemapItemsActionTypes.setSitemapItemsDragAndDrop;
  payload: SitemapItemType[];
};

export type AddSitemapItemType = {
  type: SitemapItemsActionTypes.addSitemapItem;
  payload: AddSitemapItemPayloadType;
};

export type RemoveSitemapItemType = {
  type: SitemapItemsActionTypes.removeSitemapItem;
  payload: string;
};
export type ActionType =
  | IntroActionTypes
  | SloganActionTypes
  | LocationMetaDataActionTypes
  | EventNameAndAgendaActionTypes
  | AgendaActionTypes
  | ImprintActionTypes
  | SitemapItemsActionTypes;

export type Actions = {
  type: ActionType;
  payload: string;
};

export type Action =
  | Actions
  | SetDayActionType
  | AddDayActionType
  | RemoveDayActionType
  | AddDayTalkActionType
  | RemoveTalkActionType
  | SetEventTalkActionType
  | SetLocationDetailsActionType
  | SetBackgroundType
  | SetAgendaActionType
  | SetEventNameType
  | SetSitemapMenuDragAndDropType
  | AddSitemapItemType
  | RemoveSitemapItemType;

// # Action Enums #
export enum AgendaActionTypes {
  setAgenda = 'SET_AGENDA',
  setDay = 'SET_DAY',
  addDay = 'ADD_DAY',
  removeDay = 'REMOVE_DAY',
  addDayTalk = 'ADD_DAY_TALK',
  setEventTalk = 'SET_EVENT_TALK',
  removeTalk = 'REMOVE_TALK',
}

export enum SitemapItemsActionTypes {
  setSitemapItemsDragAndDrop = 'SET_SITEMAP_ITEM_DRAG_AND_DROP',
  addSitemapItem = 'ADD_SITEMAP_ITEM',
  removeSitemapItem = 'REMOVE_SITEMAP_ITEM',
}

export enum EventNameAndAgendaActionTypes {
  setEventName = 'SET_EVENT_NAME',
  setBackground = 'SET_BACKGROUND',
}

export enum ImprintActionTypes {
  setImprint = 'SET_IMPRINT',
}

export enum IntroActionTypes {
  setIntro = 'SET_INTRO',
}

export enum LocationMetaDataActionTypes {
  setLocationDetails = 'SET_LOCATION_DETAILS',
}

export enum SloganActionTypes {
  setSlogan = 'SET_SLOGAN',
}
