import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Brush from '@mui/icons-material/Brush';
import MenuIcon from '@mui/icons-material/Menu';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatClearIcon from '@mui/icons-material/FormatClear';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CreateIcon from '@mui/icons-material/Create';
import Save from '@mui/icons-material/Save';
import ComputerIcon from '@mui/icons-material/Computer';
import AddIcon from '@mui/icons-material/Add';
import TodayIcon from '@mui/icons-material/Today';
import FontDownloadIcon from '@mui/icons-material/FontDownload';
import Image from '@mui/icons-material/Image';
import CalendarViewDayIcon from '@mui/icons-material/CalendarViewDay';
import OevSidebarLogo from '@/assets/favicons/Tagungswebsite_Logo.svg';

const iconStyles = {
  height: '0.9rem',
  width: '0.9rem',
  position: 'absolute',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  marginRight: '0.5rem',
};

export function SignetIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} sx={{ iconStyles, transform: 'translate(2px, 3px)' }}>
      <path d="M12.977,7.164C11.9,7.237,10.36,8.3,9.581,8.653A4.31,4.31,0,0,1,8,9.161c-.471,0-.541-.337-.443-1.081.023-.167.244-1.649-.889-1.584-.523.031-1.339.516-3.529,2.623L4,6.96A1.8,1.8,0,0,0,1.3,4.82L.155,5.588a.335.335,0,0,0-.1.46l.358.562a.331.331,0,0,0,.46.1L2.079,5.9a.465.465,0,0,1,.681.564L.715,11.58a.665.665,0,0,0,.618.914.659.659,0,0,0,.471-.2c.879-.879,3.221-3.138,4.4-4.077a2.161,2.161,0,0,0,.429,1.745,1.757,1.757,0,0,0,1.364.527,5.265,5.265,0,0,0,2.13-.625c.687-.308,2.061-1.3,2.882-1.37a.334.334,0,0,0,.316-.329V7.5a.33.33,0,0,0-.346-.337Z" />
    </SvgIcon>
  );
}

export function SitemapIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} sx={{ iconStyles, transform: 'translate(1px, 6px)' }}>
      <path d="M2,5.5H.5A.5.5,0,0,0,0,6V7.5A.5.5,0,0,0,.5,8H2a.5.5,0,0,0,.5-.5V6A.5.5,0,0,0,2,5.5Zm-.375-1.25h3V5h.75v-.75h3V5h.75V4.1a.6.6,0,0,0-.6-.6H5.374v-1H6A.5.5,0,0,0,6.5,2V.5A.5.5,0,0,0,6,0H4a.5.5,0,0,0-.5.5V2a.5.5,0,0,0,.5.5h.625v1H1.475a.6.6,0,0,0-.6.6V5h.75ZM5.749,5.5h-1.5a.5.5,0,0,0-.5.5V7.5a.5.5,0,0,0,.5.5h1.5a.5.5,0,0,0,.5-.5V6A.5.5,0,0,0,5.749,5.5ZM9.5,5.5H8a.5.5,0,0,0-.5.5V7.5A.5.5,0,0,0,8,8H9.5a.5.5,0,0,0,.5-.5V6A.5.5,0,0,0,9.5,5.5Z" />
    </SvgIcon>
  );
}

export function TemplateIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} sx={{ iconStyles, transform: 'translate(-1px, 2px)' }}>
      <path d="M5.222,9.386a1.331,1.331,0,0,0-1.333,1.333A.9.9,0,0,1,3,11.608a2.3,2.3,0,0,0,1.778.889,1.777,1.777,0,0,0,1.778-1.778A1.331,1.331,0,0,0,5.222,9.386Zm6.093-4.164-.6-.6a.443.443,0,0,0-.627,0L6.111,8.608,7.333,9.83l3.982-3.982a.443.443,0,0,0,0-.627Z" />
    </SvgIcon>
  );
}

export function UnsplashIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      sx={{ iconStyles, transform: 'translate(-1px, 2px)' }}
      viewBox="0 0 35.005 35.005"
    >
      <path d="M10.939,9.845V0H24.066V9.845Zm13.127,5.47H35V35H0V15.315H10.939V25.16H24.066Z" />
    </SvgIcon>
  );
}

export function CircleArrowUp(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      sx={{ iconStyles, transform: 'translate(-1px, 2px)' }}
      viewBox="0 0 34.995 34.995"
    >
      <path
        d="M20.873,3.375a17.5,17.5,0,1,0,17.5,17.5A17.495,17.495,0,0,0,20.873,3.375Zm3.651,24.32a1.63,1.63,0,0,1,0,2.3,1.6,1.6,0,0,1-1.144.471,1.632,1.632,0,0,1-1.152-.479L14.319,22.05a1.622,1.622,0,0,1,.05-2.238L22.4,11.762a1.624,1.624,0,0,1,2.3,2.3L17.8,20.873Z"
        transform="translate(38.37 -3.375) rotate(90)"
      />
    </SvgIcon>
  );
}

export function CircleArrowDown(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      sx={{ iconStyles, transform: 'translate(-1px, 2px)' }}
      viewBox="0 0 34.995 34.995"
    >
      <path
        d="M3.375,20.873a17.5,17.5,0,1,0,17.5-17.5A17.495,17.495,0,0,0,3.375,20.873Zm20.568,0-6.89-6.822a1.624,1.624,0,1,1,2.3-2.3L27.375,19.8a1.622,1.622,0,0,1,.05,2.238l-7.908,7.933a1.621,1.621,0,1,1-2.3-2.288Z"
        transform="translate(38.37 -3.375) rotate(90)"
      />
    </SvgIcon>
  );
}

export function CalendarFirstIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      sx={{ iconStyles, transform: 'translate(-1px, 2px)' }}
      viewBox="0 0 34.999 39.999"
    >
      <path d="M0,36.249A3.751,3.751,0,0,0,3.75,40h27.5A3.751,3.751,0,0,0,35,36.249V15H0Zm5-15A1.254,1.254,0,0,1,6.25,20h7.5A1.254,1.254,0,0,1,15,21.249v7.5A1.254,1.254,0,0,1,13.75,30H6.25A1.254,1.254,0,0,1,5,28.749ZM31.249,5H27.5V1.25A1.254,1.254,0,0,0,26.249,0h-2.5A1.254,1.254,0,0,0,22.5,1.25V5h-10V1.25A1.254,1.254,0,0,0,11.25,0H8.75A1.254,1.254,0,0,0,7.5,1.25V5H3.75A3.751,3.751,0,0,0,0,8.75V12.5H35V8.75A3.751,3.751,0,0,0,31.249,5Z" />
    </SvgIcon>
  );
}

export const OevLogo = OevSidebarLogo;

export const BoldIcon = FormatBoldIcon;
export const TrashCanIcon = DeleteIcon;
export const UpArrow = ArrowDropUpIcon;
export const BrushIcon = Brush;
export const HamburgerIcon = MenuIcon;
export const ItalicIcon = FormatItalicIcon;
export const UnderlineIcon = FormatUnderlinedIcon;
export const LinkIcon = InsertLinkIcon;
export const NumberedListIcon = FormatListNumberedIcon;
export const BulletedListIcon = FormatListBulletedIcon;
export const FormatClear = FormatClearIcon;
export const UploadIcon = CloudUploadIcon;
export const PenIcon = CreateIcon;
export const SaveIcon = Save;
export const Computer = ComputerIcon;
export const PlusIcon = AddIcon;
export const CalendarIcon = TodayIcon;
export const FontIcon = FontDownloadIcon;
export const ImageIcon = Image;
export const ViewDayIcon = CalendarViewDayIcon;
