import React from 'react';
import { Button, Grid, TextField, Typography } from '@mui/material';
import Headlines from '@adminComponents/Sidebar/components/Headlines';
import Editor from '@/components/Editor';
import { DayProps } from './index';
import { AgendaActionTypes } from '@/store/types';

export default function Event({ label, value, setValue, currentDayPosition, uuid }: DayProps) {
  const removeDayHandler = () => {
    setValue({
      type: AgendaActionTypes.removeDay,
      payload: {
        uuid,
      },
    });
  };
  const gridStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  };
  return (
    <Grid sx={gridStyles}>
      <Headlines>{label}</Headlines>
      <Typography style={{ marginTop: '4rem' }}>Event Description: </Typography>
      <Editor
        value={value.description}
        aria-label="Event Description Editor"
        setValue={(val: string) =>
          setValue({
            type: AgendaActionTypes.setDay,
            payload: {
              description: val,
              currentDayPosition,
            },
          })
        }
      />
      <Typography style={{ marginTop: '4rem' }}>
        Event Datum (erste Element der nächsten Seite)
      </Typography>
      <TextField
        aria-label="Event Date Input"
        variant="standard"
        sx={{ marginBottom: '1rem' }}
        value={value.date}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setValue({
            type: AgendaActionTypes.setDay,
            payload: {
              date: event.target.value,
              currentDayPosition,
            },
          })
        }
      />
      <Button
        variant="contained"
        color="error"
        onClick={removeDayHandler}
        aria-label="Remove Day Button"
      >
        Remove Day
      </Button>
    </Grid>
  );
}
