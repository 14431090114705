import styled from 'styled-components';

export const StyledWrapper = styled.span`
  margin: 5rem;
`;

export const StyledContainer = styled.div`
  height: 100vh;
  width: 100%;
`;
