import React, { Dispatch } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import Headlines from '@adminComponents/Sidebar/components/Headlines';
import { Action, AgendaActionTypes, DayType, TalkType } from '@/store/types';
import Editor from '@/components/Editor';
import { createUUID } from '@/utils/createUUID';
import { StyledContainer, StyledWrapper } from './styles';

interface EventDetailsProps {
  setValue: Dispatch<Action>;
  day: DayType;
}

export default function EventDetails({ setValue, day }: EventDetailsProps) {
  const addDayEventDetailHandler = () => {
    const newTalk: TalkType = {
      content: '',
      uuid: createUUID(),
    };
    day.talks = [...day.talks, newTalk];
    setValue({
      type: AgendaActionTypes.addDayTalk,
      payload: day,
    });
  };
  return (
    <StyledContainer>
      <Typography>Talk Description:</Typography>
      {day.talks?.map((talk, index) => (
        <StyledWrapper key={talk.uuid}>
          <Headlines>
            Event Day [{day.currentDayPosition + 1}] Talk [{index + 1}]
          </Headlines>
          <Editor
            aria-label="Event Talk Editor"
            value={talk.content}
            setValue={(val: string) => {
              setValue({
                type: AgendaActionTypes.setEventTalk,
                payload: {
                  uuid: talk.uuid,
                  content: val,
                  currentDay: day,
                },
              });
            }}
          />
          <Button
            aria-label="Remove Last Talk"
            variant="contained"
            style={{ marginTop: '1rem' }}
            color="error"
            onClick={() => {
              setValue({
                type: AgendaActionTypes.removeTalk,
                payload: {
                  talk,
                  currentDay: day,
                },
              });
            }}
          >
            Remove Last Detail
          </Button>
        </StyledWrapper>
      ))}

      <Grid container>
        <Grid item xs={6}>
          <Button
            aria-label="Add Another Talk Detail"
            variant="contained"
            style={{ marginTop: '1rem' }}
            onClick={() => addDayEventDetailHandler()}
          >
            Add Another Detail
          </Button>
        </Grid>
      </Grid>
    </StyledContainer>
  );
}
