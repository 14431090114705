import React from 'react';
import { Button, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { SignetIcon, SitemapIcon, TemplateIcon } from '@/assets/icons/Icons';

export default function ConfigurationBar() {
  return (
    <Box aria-label="Configuration Items">
      <Typography component="div" variant="smallBoldTitle">
        Konfiguration
      </Typography>
      <Button
        variant="configurationItem"
        startIcon={<SignetIcon />}
        disableRipple
        title="Signet"
        aria-label="Signet Button"
      >
        Signet
      </Button>
      <Button
        variant="configurationItem"
        startIcon={<TemplateIcon />}
        disableRipple
        title="Template"
        aria-label="Template Button"
      >
        Template
      </Button>
      <Button
        variant="configurationItem"
        startIcon={<SitemapIcon />}
        endIcon={<ArrowDropDownIcon />}
        disableRipple
        title="Sitemap"
        aria-label="Sitemap Button"
      >
        Sitemap
      </Button>
    </Box>
  );
}
