import styled from 'styled-components';
import { boxShadows, Colors } from '@styles';

export const StyledCard = styled.div<{
  background?: string;
}>`
  position: relative;
  padding: 1.4rem 1.6rem;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  row-gap: 0.5rem;
  width: 100%;

  background: ${Colors.white};
  box-shadow: ${({ background }) =>
    background === 'office' ? boxShadows.office : boxShadows.main};
  border-radius: 1.25rem;
  margin-bottom: 1.3rem;

  p {
    margin: 0;
    width: 100%;
  }

  > p {
    width: 100%;
  }
`;
