import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { Theme } from '@mui/system';
import { Colors, FontFamilies, FontSizes, fontWeights } from '@styles';

export const textField: ComponentsOverrides<Theme>['MuiTextField'] = {
  root: {
    textAlign: 'left',
    width: '12rem',
    height: '0.75rem',
    fontSize: FontSizes.xs,
    fontFamily: FontFamilies.golos,
    '& input': {
      padding: 0,
      borderBottom: `solid 1px ${Colors.mineShaft}`,
      '&:hover': {
        borderBottom: `solid 1px ${Colors.mineShaft}`,
        fontWeight: fontWeights.semiBold,
        cursor: 'pointer',
      },
      '&:disabled': {
        color: Colors.nobel,
        borderBottom: `solid 1px ${Colors.nobel}`,
        '&:hover': {
          fontWeight: fontWeights.stringNormal,
        },
      },
    },
  },
};
