import React, { ReactNode } from 'react';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { Colors } from '@styles';
import Interpreter from '@/components/Editor/Interpreter';
import Animations from '@/components/Animations';
import { ChildComponentType, CMSDataType, ContainerProps } from '@/components/ContainerComponent';
import RedirectButton from '@/components/RedirectButton/RedirectButton';
import {
  InnerWrapper,
  StyledContent,
  StyledFooter,
  StyledFooterFirst,
  StyledWrapper,
  Wrapper,
} from '@/components/ContainerComponent/styles';
import appRoutes from '@/routes/appRoutes';

export default function ContainerComponent({
  id,
  image,
  footer,
  height,
  header,
  body,
  background,
  redirect,
}: ContainerProps) {
  const getContent = (data: CMSDataType | ChildComponentType) => {
    if ((data as CMSDataType)?.xs) {
      if ((data as CMSDataType).data === undefined) return <Animations />;
      const cmsData = data as CMSDataType;
      return <Interpreter value={cmsData.data || ''} fontColor={Colors.white} />;
    }
    return data as ReactNode;
  };
  const isAdminPage = window.location.pathname.includes(appRoutes[0].key);
  const Content = (
    <StyledContent>
      {header && getContent(header)}
      <StyledWrapper>
        {body && getContent(body)}{' '}
        {id === 'first' ? (
          footer && (
            <StyledFooterFirst>
              {' '}
              <Typography variant={background === 'office' ? 'officeTheme' : 'berlinAndTechTheme'}>
                {footer && getContent(footer)}
              </Typography>
            </StyledFooterFirst>
          )
        ) : (
          <StyledFooter>
            <Typography variant={background === 'office' ? 'officeTheme' : 'berlinAndTechTheme'}>
              {footer && getContent(footer)}
            </Typography>
          </StyledFooter>
        )}
        <Grid>{redirect && <RedirectButton background={background} redirect={redirect} />}</Grid>
      </StyledWrapper>
    </StyledContent>
  );
  return (
    <Wrapper
      id={id}
      image={image}
      background={background}
      container
      height={height}
      direction="column"
      $isAdminPage={isAdminPage}
    >
      <InnerWrapper container item direction="column" xs={12}>
        {Content}
      </InnerWrapper>
    </Wrapper>
  );
}
