import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { styled } from '@mui/system';
import LocationCard from '@/components/LocationCard';
import Contact from '@/components/Contact';
import { fetchTagungsAppConfig } from '@/api/consumer/consumerApi';
import { AppStateType, DayType, TalkType } from '@/store/types';
import ContainerComponent from '@/components/ContainerComponent';
import Header from '@/components/Header';
import Card from '@/components/Card';
import '@/styles/globals.css';
import { LandingProps } from './interfaces';
import { SupportedThemes, themesMap } from '@/utils/themes';

const Container = styled(Grid)({
  margin: '0 auto',
  width: '100vw',
});

function Landing({ appStateDefaults }: LandingProps) {
  const [eventData, setEventData] = useState<AppStateType>(appStateDefaults as AppStateType);
  const berlinTheme = themesMap.get(SupportedThemes.BERLIN);

  const jumpTo = (id: string) => {
    const url = window.location.href;
    window.location.href = `#${id}`;
    window.history.replaceState(null, '', url);
  };

  useEffect(() => {
    if (appStateDefaults === undefined) return;
    setEventData(appStateDefaults);
  }, [appStateDefaults]);

  useEffect(() => {
    if (!window.location.pathname || window.location.pathname.includes('admind033e22ae')) {
      return;
    }
    fetchTagungsAppConfig(window.location.pathname.replace('/', '')).then((state) => {
      if (Array.isArray(state)) {
        const appStateArray = state as AppStateType[];
        return setEventData(appStateArray[0]);
      }
      return setEventData(state);
    });
  }, []);

  return (
    <Container>
      <ContainerComponent
        area-label="Event Intro"
        background={eventData?.background?.name}
        id="first"
        image={eventData?.background?.firstPage || berlinTheme?.firstPage}
        header={<Header />}
        footer={{ data: eventData?.intro, xs: 1 }}
        redirect={() => jumpTo('second')}
      />
      <ContainerComponent
        area-label="Event Slogan"
        background={eventData?.background?.name}
        id="second"
        image={eventData?.background?.secondPage || berlinTheme?.secondPage}
        footer={{ data: eventData?.slogan, xs: 1 }}
      />
      <ContainerComponent
        area-label="Event Location Details"
        background={eventData?.background?.name}
        id="third"
        image={eventData?.background?.thirdPage || berlinTheme?.thirdPage}
        height="100%"
        body={
          <>
            <LocationCard
              background={eventData?.background?.name}
              dateRange={eventData?.locationMetaData?.dateRange}
            />
            <LocationCard
              background={eventData?.background?.name}
              locationImage={eventData?.locationMetaData?.locationImage}
              locationName={eventData?.locationMetaData?.locationName}
            />
            <LocationCard
              background={eventData?.background?.name}
              address={eventData?.locationMetaData?.address}
            />
          </>
        }
      />
      {eventData?.agenda?.map((event: DayType) => (
        <div key={event.uuid}>
          <ContainerComponent
            area-label="Event Agenda"
            background={eventData?.background?.name}
            id="forth"
            image={eventData?.background?.fourthPage || berlinTheme?.fourthPage}
            footer={{ data: event.description, xs: 1 }}
          />
          <ContainerComponent
            area-label="Event Agenda"
            background={eventData?.background?.name}
            id="seventh"
            image={eventData?.background?.fifthPage || berlinTheme?.fifthPage}
            height="100%"
            body={
              <>
                <LocationCard dateRange={event.date} />
                {event.talks?.map((talk: TalkType) => (
                  <Card
                    area-label="Event Talk Agenda"
                    key={talk.uuid}
                    value={talk.content}
                    background={eventData?.background?.name}
                  />
                ))}
              </>
            }
          />
        </div>
      ))}
      <ContainerComponent
        area-label="Event Imprint"
        background={eventData?.background?.name}
        id="tenth"
        height="100%"
        image={eventData?.background?.fourthPage || berlinTheme?.fourthPage}
        body={<Contact value={eventData?.imprint || ''} background={eventData?.background?.name} />}
      />
    </Container>
  );
}

export default Landing;
