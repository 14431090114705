import React, { createContext, ReactNode, useMemo, useReducer } from 'react';
import { Action, LocationMetaDataType } from '@/store/types';
import { LocationMetaDataReducer } from '@/store/reducer/LocationMetaDataReducer';

export interface LocationMetaDataContextProps {
  locationMetaDataEditor: LocationMetaDataType;
  locationMetaDataDispatch: React.Dispatch<Action>;
}

export const locationMetaDataState: LocationMetaDataType = {
  locationMetaData: {
    dateRange: '',
    locationName: '',
    address: '',
    locationImage: {
      contentType: '',
      name: '',
      data: '',
    },
  },
};

const LocationMetaDataContext = createContext<LocationMetaDataContextProps>({
  locationMetaDataEditor: locationMetaDataState,
  locationMetaDataDispatch: () => null,
});

function LocationMetaDataProvider({ children }: { children: ReactNode }) {
  const [locationMetaDataEditor, locationMetaDataDispatch] = useReducer(
    LocationMetaDataReducer,
    locationMetaDataState
  );
  const contextValue = useMemo(
    () => ({
      locationMetaDataEditor,
      locationMetaDataDispatch,
    }),
    [locationMetaDataEditor]
  );
  return (
    <LocationMetaDataContext.Provider value={contextValue}>
      {children}
    </LocationMetaDataContext.Provider>
  );
}

export { LocationMetaDataProvider, LocationMetaDataContext };
