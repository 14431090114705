import React, { useState } from 'react';
import { Button } from '@mui/material';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveSitemapItemModal from '@adminComponents/Sidebar/components/RemoveSitemapItemModal';
import { SitemapItemProps } from './interfaces';

export default function SitemapItem({
  text,
  supportedView,
  setSelectedEditor,
  id,
}: SitemapItemProps) {
  const [openRemoveSitemapItemModal, setOpenRemoveSitemapItemModal] = useState(false);
  return (
    <>
      <Button
        variant="sitemapItem"
        disableRipple
        onClick={() => setSelectedEditor(supportedView)}
        startIcon={<MenuOutlinedIcon />}
        title={text}
        aria-label={`${text} Button`}
        endIcon={
          <DeleteIcon onClick={() => setOpenRemoveSitemapItemModal(!openRemoveSitemapItemModal)} />
        }
        size="small"
      >
        {text}
      </Button>
      <RemoveSitemapItemModal
        openModal={openRemoveSitemapItemModal}
        setOpenModal={setOpenRemoveSitemapItemModal}
        id={id}
        text={text}
      />
    </>
  );
}
